import { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  required,
  passwordMatch,
  email as isEmail,
  validCountryCode,
  validPassword,
  codeRequired,
  phoneRequired,
  containsOnlyNumbers,
  requiredPicture,
  fileSize,
} from 'common/utils/validations';
import { reasonOptions, salutationOptions } from 'common/constants/forms';
import Help from 'common/components/Registration/Help';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const AccountInfoForm = ({
  register,
  control,
  watch,
  errors,
  countries,
  trigger,
  cities,
  firms,
  t,
  isValid,
}) => {
  const [preview, setPreview] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

  const { t: vt } = useTranslation('validations');

  const values = watch();

  const {
    first_name,
    last_name,
    email,
    password,
    salutation,
    office_direct_phone_country_code,
    office_direct_phone,
    mobile_phone_country_code,
    mobile_phone,
  } = values.userData;

  const { country, city, firm } = values.professionalData;
  const { passwordAgain } = values;

  const profile_photo_url = watch('userData.profile_photo_url');

  const isValidForm =
    !!salutation &&
    !!first_name &&
    !!last_name &&
    !!email &&
    !!password &&
    !!passwordAgain &&
    !!country &&
    !!city &&
    !!firm &&
    !!profile_photo_url &&
    !errors.userData;

  const handlePreview = () => {
    if (profile_photo_url?.length) {
      if (typeof profile_photo_url === 'string') {
        return setPreview(profile_photo_url);
      }
      if (typeof profile_photo_url === 'object' && profile_photo_url) {
        return setPreview(URL.createObjectURL(profile_photo_url[0]));
      }
    }
    return setPreview('');
  };

  useEffect(() => {
    trigger(['userData.office_direct_phone_country_code', 'userData.office_direct_phone']);
  }, [office_direct_phone_country_code, office_direct_phone]);

  useEffect(() => {
    trigger(['userData.mobile_phone_country_code', 'userData.mobile_phone']);
  }, [mobile_phone_country_code, mobile_phone]);

  useEffect(() => {
    handlePreview();
  }, [profile_photo_url]);

  return (
    <div className="account__form">
      <p className="account__required">{t('requiredInformation')}</p>
      <div className="account__row">
        <div className="account__names">
          <div className="account__firstnames">
            <div className="account__salutation">
              <label>{`${t('salutation')}*`}</label>
              <Controller
                name="userData.salutation"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field, ref }) => (
                  <Select
                    ref={ref}
                    options={salutationOptions}
                    className="react-select-container"
                    classNamePrefix={
                      errors.userData?.salutation ? 'react-select-error' : 'react-select'
                    }
                    defaultValue={salutationOptions.find(option => option.value === field.value)}
                    onChange={val => field.onChange(val.value)}
                  />
                )}
              />
              <span className="input__error">{errors.userData?.salutation?.message}</span>
            </div>
            <div className="user__input account__name">
              <label>{`${t('givenNames')}*`}</label>
              <input
                {...register('userData.first_name', {
                  validate: { required: value => required(value, vt) },
                })}
                name="userData.first_name"
                placeholder={t('firstName')}
                className={`form-control input__container ${
                  errors.userData?.first_name ? 'form__input--error' : ''
                }`}
              />
              <span className="input__error">{errors.userData?.first_name?.message}</span>
            </div>
          </div>

          <div className="user__input account__surname">
            <label>{`${t('familyName')}*`}</label>
            <input
              {...register('userData.last_name', {
                validate: { required: value => required(value, vt) },
              })}
              name="userData.last_name"
              label={t('familyName')}
              placeholder={t('lastName')}
              className={`form-control input__container ${
                errors.userData?.last_name ? 'form__input--error' : ''
              }`}
            />
            <span className="input__error">{errors.userData?.last_name?.message}</span>
          </div>
          <div className="user__input account__email">
            <label>{`${t('officeEmail')}*`}</label>
            <input
              {...register('userData.email', {
                validate: {
                  required: value => required(value, vt),
                  isEmail: value => isEmail(value, vt),
                },
              })}
              name="userData.email"
              placeholder={t('officeEmail')}
              className={`form-control input__container ${
                errors.userData?.email ? 'form__input--error' : ''
              }`}
            />
            <span className="input__error">{errors.userData?.email?.message}</span>
          </div>
        </div>

        <div className="profile__files">
          <div className="profile__actions">
            <div className="user__input--photo">
              <div className="profile__img-container">
                {preview && (
                  <img src={preview} alt="Your profile picture" className="profile__img" />
                )}
              </div>
              <div className="user__input--photo-action">
                <label className="profile__btn" htmlFor="profile_photo_url">
                  {preview ? t('updatePhoto') : `${t('uploadPhoto')}`}
                </label>
                <span className="input__error--photo">
                  {errors.userData?.profile_photo_url?.message}
                </span>
              </div>
              <input
                {...register('userData.profile_photo_url', {
                  validate: {
                    requiredPicture: value => requiredPicture(value, vt),
                    fileSize: value => fileSize(value, vt, 'image'),
                  },
                })}
                className="hidden"
                name="userData.profile_photo_url"
                id="profile_photo_url"
                type="file"
                accept=".jpg, .jpeg, .png"
              />
              <div className="help__container">
                <span className="help__text">
                  <i className={`help__tooltip fas fa-info-circle`} />
                  {t('photoRequirements')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="account__row account__row--centered flex-wrap">
        <div className="user__input account__password">
          <label>{`${t('createPassword')}*`}</label>
          <input
            {...register('userData.password', {
              validate: {
                required: value => required(value, vt),
                validPassword: value => validPassword(value, vt),
              },
            })}
            name="userData.password"
            type={showPassword ? 'text' : 'password'}
            label={t('createPassword')}
            placeholder={t('newPassword')}
            className={`form-control input__container ${
              errors.userData?.password ? 'form__input--error' : ''
            }`}
          />
          <i
            className={`visibility__icon ${showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}`}
            onClick={() => setShowPassword(!showPassword)}
          />
          <span className="input__error">{errors.userData?.password?.message}</span>
        </div>

        <div className="user__input account__password">
          <label>{`${t('reEnterPasswordTo')}*`}</label>
          <input
            name="passwordAgain"
            type={showConfirmationPassword ? 'text' : 'password'}
            placeholder={t('reEnterPassword')}
            {...register('passwordAgain', {
              validate: {
                required: value => required(value, vt),
                match: value => passwordMatch(value, password, vt),
              },
            })}
            className={`form-control input__container ${
              errors.passwordAgain ? 'form__input--error' : ''
            }`}
          />
          <i
            className={`visibility__icon ${
              showConfirmationPassword ? 'fas fa-eye' : 'fas fa-eye-slash'
            }`}
            onClick={() => setShowConfirmationPassword(!showConfirmationPassword)}
          />
          <span className="input__error">{errors.passwordAgain?.message}</span>
        </div>

        <div className="account__password-info">
          <Help text={t('passwordRequirements')} icon="fas fa-info-circle" />
        </div>
      </div>
      <div className="account__row">
        <div className="account__phones">
          <label>{t('directOfficeNumber')}</label>
          <div className="account__phone">
            <div className="account__code">
              <input
                {...register('userData.office_direct_phone_country_code', {
                  validate: {
                    isCountryCode: code => validCountryCode(code, vt),
                    codeRequired: () =>
                      codeRequired(office_direct_phone_country_code, office_direct_phone, vt),
                  },
                })}
                name="userData.office_direct_phone_country_code"
                placeholder={t('countryCode')}
                className={`form-control input__container  ${
                  errors.userData?.office_direct_phone_country_code ? 'form__input--error' : ''
                }`}
              />
              <span className="input__error">
                {errors.userData?.office_direct_phone_country_code?.message}
              </span>
            </div>
            <div className="account__number">
              <input
                {...register('userData.office_direct_phone', {
                  validate: {
                    phoneRequired: () =>
                      phoneRequired(office_direct_phone_country_code, office_direct_phone, vt),
                    containsOnlyNumbers: phone => containsOnlyNumbers(phone, vt),
                  },
                })}
                name="userData.office_direct_phone"
                placeholder={t('directOfficeNumber')}
                className={`form-control input__container  ${
                  errors.userData?.office_direct_phone ? 'form__input--error' : ''
                }`}
              />
              <span className="input__error">{errors.userData?.office_direct_phone?.message}</span>
            </div>
          </div>
        </div>
        <div className="account__phones">
          <label>{t('mobileNumber')}</label>
          <div className="account__phone">
            <div className="account__code">
              <input
                {...register('userData.mobile_phone_country_code', {
                  validate: {
                    isCountryCode: code => validCountryCode(code, vt),
                    codeRequired: () => codeRequired(mobile_phone_country_code, mobile_phone, vt),
                  },
                })}
                name="userData.mobile_phone_country_code"
                placeholder={t('countryCode')}
                className={`form-control input__container ${
                  errors.userData?.mobile_phone_country_code ? 'form__input--error' : ''
                }`}
              />
              <span className="input__error">
                {errors.userData?.mobile_phone_country_code?.message}
              </span>
            </div>
            <div className="account__number">
              <input
                {...register('userData.mobile_phone', {
                  validate: {
                    phoneRequired: () => phoneRequired(mobile_phone_country_code, mobile_phone, vt),
                    containsOnlyNumbers: phone => containsOnlyNumbers(phone, vt),
                  },
                })}
                name="userData.mobile_phone"
                placeholder={t('mobileNumber')}
                className={`form-control input__container ${
                  errors.userData?.mobile_phone ? 'form__input--error' : ''
                }`}
              />
              <span className="input__error">{errors.userData?.mobile_phone?.message}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="account__row">
        <div className="account__country">
          <label>{`${t('memberFirmCountry')}*`}</label>
          <Controller
            name="professionalData.country"
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, ref }) => (
              <Select
                ref={ref}
                options={countries}
                className="react-select-container"
                classNamePrefix={
                  errors.professionalData?.country ? 'react-select-error' : 'react-select'
                }
                defaultValue={countries.find(option => option.value === field.value)}
                onChange={val => {
                  field.onChange(val.value);
                }}
              />
            )}
          />
          <span className="input__error">{errors.professionalData?.country?.message}</span>
        </div>
        <div className="account__city">
          <label>{`${t('memberFirmCity')}*`}</label>
          <Controller
            name="professionalData.city"
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, ref }) => (
              <Select
                ref={ref}
                options={cities}
                className="react-select-container"
                classNamePrefix={
                  errors.professionalData?.city ? 'react-select-error' : 'react-select'
                }
                defaultValue={cities.find(option => option.value === field.value)}
                onChange={val => field.onChange(val.value)}
              />
            )}
          />
          <span className="input__error">{errors.professionalData?.city?.message}</span>
        </div>
        <div className="account__firm">
          <label>{`${t('memberFirm')}*`}</label>
          <Controller
            name="professionalData.firm"
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, ref }) => (
              <Select
                ref={ref}
                options={firms}
                className="react-select-container"
                classNamePrefix={
                  errors.professionalData?.firm ? 'react-select-error' : 'react-select'
                }
                defaultValue={firms.find(option => option.value === field.value)}
                onChange={val => field.onChange(val.value)}
              />
            )}
          />
          <span className="input__error">{errors.professionalData?.firm?.message}</span>
        </div>
      </div>
      <div className="account__row account__row--last">
        <div className="account__info-container--start">
          <Help
            text={t('problem')}
            icon="fas fa-info-circle"
            link="mailto:directory@crowe.org?Subject=Web Portal Problems"
          />
        </div>
        <div className="account__reason">
          <label>{t('reason')}</label>
          <Controller
            name="userData.reasonForRegistering"
            control={control}
            render={({ field, ref }) => (
              <Select
                ref={ref}
                options={reasonOptions}
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={reasonOptions.find(option => option.value === field.value)}
                onChange={val => field.onChange(val.value)}
              />
            )}
          />
        </div>
      </div>
      <div className="account__footer">
        <div className="account__actions">
          <Link to="/login" className="account__cancel">
            Cancel
          </Link>
          <button
            key="submit"
            type="submit"
            label={t('completeRegistration')}
            disabled={!isValidForm && !isValid}
            className={`registration__btn registration__btn--complete ${
              !isValidForm && !isValid
                ? 'registration__btn--disabled'
                : 'registration__btn--enabled'
            }`}
          >
            {t('complete')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountInfoForm;
