import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGet } from 'hooks/useGet';
import { getTerms } from 'services/legal';
import Markdown from 'markdown-to-jsx';

const Terms = ({ handleTerms }) => {
  const { t } = useTranslation('registration');

  const { data: termsContent } = useGet(getTerms);

  return (
    <div>
      {termsContent?.fields.body && (
        <div className="markdown">
          <Markdown>{termsContent.fields.body}</Markdown>
        </div>
      )}
      {handleTerms && (
        <div className="terms__actions">
          <button key="terms" type="button" className="terms__accept" onClick={() => handleTerms()}>
            {t('acceptTerms')}
          </button>
          <Link to="/login" className="terms__cancel">
            {t('cancelTerms')}
          </Link>
        </div>
      )}
    </div>
  );
};

export default Terms;
